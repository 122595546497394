import {
  ActivityCode,
  Content,
  ContentType,
  InputCurrencyCode,
  InterestCode,
  MarketApi as MarketApiClient,
  ProductCategoryCode,
  ProductType,
  SearchType,
  UserProductType,
} from 'pleinchamp-api-client';
import { ApiConfiguration, axiosInstance } from '@api/business/api.conf';
import { ContentApi } from '@api/business/api.utils';
import { searchContent } from '@search/business/search.utils';
import { Constants } from '@utils/Constants';
import { Dayjs } from 'dayjs';

export const MarketApi = new MarketApiClient(ApiConfiguration, undefined, axiosInstance);

export async function fetchMarkets(productCategoryCode?: ProductCategoryCode) {
  if (productCategoryCode === ProductCategoryCode.Cattle) {
    const { data } = await MarketApi.getMarkets(productCategoryCode);
    return data;
  }
  return [];
}

export async function fetchBoxLinksCattle() {
  const { data } = await MarketApi.getBoxLinksCattle();

  return data;
}

export async function getProductTypes({
  marketId,
  shouldGapFill,
  productCategoryCode,
  isRefMarket,
  quoteDate,
}: {
  marketId?: number;
  shouldGapFill?: boolean;
  productCategoryCode?: ProductCategoryCode;
  isRefMarket?: boolean;
  quoteDate?: Dayjs;
}) {
  const { data } = await MarketApi.getProductTypes(
    marketId,
    shouldGapFill,
    isRefMarket,
    productCategoryCode,
    quoteDate ? quoteDate.toDate() : undefined
  );
  return data;
}

export async function getSingleProductType({
  productTypeId,
  marketId,
  productCategoryCode,
  shouldGapFill,
  isRefMarket,
  quoteDate,
}: {
  productTypeId: number;
  marketId?: number;
  shouldGapFill?: boolean;
  isRefMarket?: boolean;
  quoteDate?: Dayjs;
  productCategoryCode?: ProductCategoryCode;
}) {
  const { data } = await MarketApi.getProductType(
    productTypeId,
    marketId,
    productCategoryCode === ProductCategoryCode.Cattle ? false : shouldGapFill || true,
    isRefMarket,
    productCategoryCode,
    quoteDate ? quoteDate.toDate() : undefined
  );
  return data;
}

export async function getCattleMarketAnalysis() {
  try {
    const { contents } = await searchContent({
      activities: [ActivityCode.Breeding],
      contentTypes: [ContentType.Article],
      interests: [InterestCode.CoursesMarket],
      isHighlighted: false,
      isPro: true,
      limit: 1,
      offset: 0,
      query: "Analyse commerciale à l'échelle nationale",
      searchType: SearchType.Prioritize,
    });
    if (contents[0]) {
      const liteArticle = contents[0];
      const article = await ContentApi.getArticle(liteArticle.slug);
      return article.data;
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
}

export function getStocksNews(productCategoryCode?: ProductCategoryCode): Promise<Content[]> {
  const activities: ActivityCode[] = [];
  const interests: InterestCode[] = [];

  switch (productCategoryCode) {
    case ProductCategoryCode.Crops:
      activities.push(ActivityCode.BigCrops);
      break;
    case ProductCategoryCode.Pork:
    case ProductCategoryCode.CattleFood:
    case ProductCategoryCode.Cattle:
      activities.push(ActivityCode.Breeding);
      break;
    case ProductCategoryCode.Energies:
    default:
      break;
  }

  interests.push(InterestCode.CoursesMarket);

  return searchContent({
    activities: activities.length > 0 ? activities : undefined,
    contentTypes: [ContentType.Article],
    interests: interests.length > 0 ? interests : undefined,
    limit: Constants.FEATURED_NEWS_LIMIT,
    searchType: SearchType.Filter,
  })
    .then(response => response.contents)
    .catch(err => {
      console.error(err);
      return Constants.EMPTY_ARRAY as Content[];
    });
}

export function getProductQuotes(productTypeId: number, productId: number) {
  return MarketApi.getQuotes(productTypeId, productId).then(response => response.data);
}

export async function getProductType(productTypeId: number): Promise<ProductType | UserProductType> {
  const response = await MarketApi.getProductType(productTypeId);
  return response.data;
}

export async function getForeignExchangeRates(code = InputCurrencyCode.Eur) {
  const { data } = await MarketApi.getCurrency(code);
  return data;
}
